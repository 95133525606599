import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";

const Header = () => (
  <header
    id="inicio"
    class={`${style.header} col-lg-6 col-md-6 col-sm-12 col-xs-12`}
  >
    <a
      href="#inicio"
      class={`${style.logo} col-lg-6 col-md-6 col-sm-12 col-xs-12`}
    >
      <img src="../../assets/solecblanco.svg" height="100" width="200" />
    </a>
    <nav class={`${style.NavMenu} col-lg-6 col-md-6 col-sm-12 col-xs-12`}>
      <a href="#conocenos">
        <h4>Conócenos</h4>
      </a>
      <div class={style.borderleft}>|</div>
      <a href="#Contactanos">
        <h4>Contactanos</h4>
      </a>
      <div class={style.borderleft}>|</div>
      <a
        href="https://www.facebook.com/solecmx"
        target="_blank"
        className={style.logo}
      >
        <img src="../../assets/facebook_white.png" height="20" width="20" />
      </a>
    </nav>
  </header>
);

export default Header;
